<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.clearMoneyReport')">
      <div class="row">
        <div class="col-sm-6 col-md-4">
          {{ $t('message.machineId') }} : <br>
          <list-select
            :list="machineData"
            :selected-item="selectedId"
            placeholder="Select Machine ID"
            option-value="machineId"
            option-text="name"
            @select="onSelect"
          />
        </div>
        <div class="col-sm-6 col-md-3">
          {{ $t('message.date') }} :
          <br>
          <date-picker range type="date" v-model="date" lang="en" format="yyyy-MM-dd" width="220"></date-picker>
          <!--
          <model-list-select 
            :list="machineData"
            v-model="searchData.id"
            option-value="machineId"
            option-text="machineId"
            placeholder="Select Machine ID">
          </model-list-select>
          -->
        </div>
        <div class="col-sm-6 col-md-3">
          <br>
          <b-button v-on:click="getClearMoneyReport()" variant="primary">
            <i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}
          </b-button>
        </div>
      </div>
      <br>

      <b-list-group-item class="table-responsive" v-if="rowData.length > 0">
        <table class="table2">
          <thead style="text-align-last: center">
            <tr>
              <th>{{ $t('message.machineId') }}</th>
              <th>{{ $t('message.date') }}</th>
              <th>{{ $t('message.coin') }}</th>
              <th>{{ $t('message.banknote') }}</th>
              <th>{{ $t('message.balance') }}</th>
              <th>{{ $t('message.report') }}</th>
            </tr>
          </thead>
          <tbody style="text-align-last: center">
            <template v-for="(items, index) in rowData ">
              <tr>
                <td>{{items.machineId}}</td>
                <td>{{items.timeStamp | moment("YYYY-MM-DD HH:mm")}}</td>
                <td>
                  <span class="spanCustom" @click="showModal(items.value.data)">{{items.totalCoin}}</span>
                </td>
                <td>
                  <span class="spanCustom" @click="showModal(items.value.data)">{{items.totalBill}}</span>
                </td>
                <td>{{webServices.addCommas(items.value.data.moneyInboxServer.total)}}</td>
                <td>
                  <b-btn variant="success" @click="getReport(items)">{{ $t('message.modalTitle.summary') }}</b-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <br>

        <b-pagination
          size="md"
          :total-rows="totalRows"
          v-model="pagination"
          :per-page="itemperPage"
          @input="pageChanged(pagination)"
        ></b-pagination>
        <!--        <uib-pagination :total-items=totalRows :items-per-page=itemperPage v-model="pagination" class="pagination-sm" @change="pageChanged(pagination.currentPage)"></uib-pagination>-->
      </b-list-group-item>
      <b-list-group-item v-else>
        <p style="text-align: center">{{ $t('message.transactionNotFound') }}</p>
      </b-list-group-item>
    </b-card>

    <b-modal ref="reportClearmoney" hide-header size="lg">
      <table class="table-modal">
        <thead>
          <tr>
            <th>{{ $t('message.productId') }}</th>
            <th>{{ $t('message.productName') }}</th>
            <th style="text-align: right">{{ $t('message.amount') }}</th>
            <th style="text-align: right">{{ $t('message.sale') }}</th>
          </tr>
        </thead>
        <template v-for="item in reportData">
          <tr>
            <td>{{item.productId}}</td>
            <td v-if="item.product.name">{{item.product.name}}</td>
            <td style="text-align: right">{{webServices.addCommas(item.qty)}}</td>
            <td style="text-align: right">{{webServices.addCommas(item.price)}}</td>
          </tr>
        </template>
        <!--
        <tfoot>
          <tr>
            <th></th>
            <th>สรุป</th>
            <th style="text-align: right">{{webServices.addCommas(reportData.totalQty)}}</th>
            <th style="text-align: right">{{webServices.addCommas(reportData.totalPrice)}}</th>
          </tr>
        </tfoot>
        -->
      </table>
      <div slot="modal-footer" class="w-100">
        <download-excel
          class="btn btn-success float-right"
          :data="reportData"
          :fields="exportField"
          :name="reportFileName"
          :title="title"
        >
          Export
          <i class="icon-docs"></i>
        </download-excel>
        <b-btn variant="danger" class="float-left" @click="closeReportModal()">Close</b-btn>
      </div>
    </b-modal>

    <b-modal ref="detailModalMoney" hide-footer hide-header>
      <table class="table-modal" style="text-align: center">
        <thead style="text-align-last: center">
          <th>{{ $t('message.type') }}</th>
          <th>{{ $t('message.amount') }}</th>
          <th>{{ $t('message.balance') }}</th>
        </thead>
        <tbody>
          <tr>
            <th>{{ $t('message.coin') }}</th>
            <td></td>
            <td></td>
          </tr>
          <template v-for="(value, key) in modalData.moneyInboxServer.coin">
            <tr>
              <td>{{key}} {{ $t('message.currency') }}</td>
              <td>{{webServices.addCommas(value)}}</td>
              <td>{{webServices.addCommas(value*key)}} {{ $t('message.currency') }}</td>
            </tr>
          </template>
          <tr>
            <th>{{ $t('message.banknote') }}</th>
            <td></td>
            <td></td>
          </tr>
          <template v-for="(value, key) in modalData.moneyInboxServer.bill">
            <tr>
              <td>{{key}} {{ $t('message.currency') }}</td>
              <td>{{webServices.addCommas(value)}}</td>
              <td>{{webServices.addCommas(value*key)}} {{ $t('message.currency') }}</td>
            </tr>
          </template>
        </tbody>
        <tfoot style="text-align-last: center">
          <td></td>
          <th>{{ $t('message.sum') }}</th>
          <th>{{webServices.addCommas(modalData.moneyInboxServer.total)}} {{ $t('message.currency') }}</th>
        </tfoot>
      </table>
    </b-modal>
  </div>
</template>
<script>
import webServices from "../../script";
import moment from "moment";
import DatePicker from "../../custom_modules/vue2-datepicker";
import {
  ListSelect,
  ModelListSelect
} from "../../custom_modules/search-select";
export default {
  components: {
    moment,
    ModelListSelect,
    ListSelect,
    DatePicker
  },
  data() {
    return {
      webServices: webServices,
      rowData: [],
      machineData: [],
      selectedId: {},
      totalRows: 1,
      pagination: 1,
      itemperPage: 20,
      searchData: {
        userId: window.localStorage.getItem("userId"),
        machineId: "",
        page: 1,
        rows: 20
      },
      modalData: {
        moneyInboxServer: {
          coin: "",
          bill: ""
        }
      },
      reportData: [],
      date: [
        new Date().setHours(0, 0, 0, 0),
        new Date().setHours(23, 59, 59, 999)
      ],
      exportField: {
        //  หมายเลขตู้: 'machineId',
        รหัสสินค้า: "productId",
        sku: "product.sku",
        ชื่อสินค้า: "product.name",
        จำนวน: "qty",
        ยอดขาย: "price"
      },
      reportFileName: "",
      title: []
    };
  },
  methods: {
    getClearMoneyReport() {
      this.$Progress.start();
      this.searchData.from = moment(this.date[0]).format("YYYY-MM-DD HH:mm:ss");
      this.searchData.to = moment(this.date[1]).format("YYYY-MM-DD HH:mm:ss");
      // console.log(this.searchData)
      webServices
        .getClearInboxReport(this.searchData)
        .then(res => {
          this.$Progress.finish();
          // console.log(res.data)
          this.rowData = res.data.rows;
          this.totalRows = res.data.count;
          for (var i = 0; i < this.rowData.length; i++) {
            var totalCoin = 0;
            var totalBill = 0;
            Object.keys(
              this.rowData[i].value.data.moneyInboxServer.coin
            ).forEach(key => {
              var total = 0;
              total =
                key * this.rowData[i].value.data.moneyInboxServer.coin[key];
              totalCoin += total;
            });
            Object.keys(
              this.rowData[i].value.data.moneyInboxServer.bill
            ).forEach(key => {
              var total = 0;
              total =
                key * this.rowData[i].value.data.moneyInboxServer.bill[key];
              totalBill += total;
            });
            this.rowData[i].totalCoin = webServices.addCommas(totalCoin);
            this.rowData[i].totalBill = webServices.addCommas(totalBill);
          }
          this.selectedId = {};
          this.searchData.machineId = "";
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log(err);
          console.log("error Clear money report");
          this.$toast.error({
            title: "ERROR",
            message: err.toString()
          });
        });
    },
    closeReportModal() {
      this.$refs.reportClearmoney.hide();
    },
    onSelect(item) {
      this.selectedId = item;
      this.searchData.machineId = item.machineId;
    },
    showModal(data) {
      this.modalData = data;
      this.$refs.detailModalMoney.show();
    },
    pageChanged(PageNum) {
      this.searchData.page = PageNum;
      this.getClearMoneyReport();
    },
    getMachine() {
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then(res => {
          this.machineData = res.data;
          // console.log(this.machineData)
        })
        .catch(err => {
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    getReport(data) {
      this.$Progress.start();
      let reportParams = {};
      reportParams.end = moment(data.timeStamp).format("YYYY-MM-DD HH:mm:ss");
      reportParams.id = data.machineId;
      if (data.value.lastevent) {
        reportParams.start = moment(data.value.lastevent).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        console.log(reportParams);
        this.getReportByProduct(reportParams);
      } else {
        const machine = {
          machineId: data.machineId,
          userId: window.localStorage.getItem("userId"),
          page: 1,
          rows: 20,
          typeId: "",
          mode: "",
          models: ""
        };
        webServices.getMachine(machine).then(res => {
          this.$Progress.finish();
          reportParams.start = moment(res.data.rows[0].createdAt).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          console.log(reportParams);
          this.getReportByProduct(reportParams);
        });
      }
    },
    getReportByProduct(data) {
      this.$Progress.start();
      webServices
        .getReportByProduct(data)
        .then(res => {
          this.$Progress.finish();
          console.log(res.data)
          this.reportData = res.data.row;
          this.reportData.totalPrice = res.data.totalAmount;
          this.reportData.totalQty = res.data.totalQty;
          this.reportData.push({
            machineId: "",
            productId: "",
            product: {
              sku: "",
              name: "สรุป"
            },
            qty: res.data.totalQty,
            price: res.data.totalAmount
          });
          this.$refs.reportClearmoney.show();
          this.reportFileName = `Report_${data.id}_${moment(data.start).format(
            "YYYY-MM-DD"
          )}_to_${moment(data.end).format("YYYY-MM-DD")}.xls`;
          this.title[0] = `หมายเลขตู้ ${data.id}`;
          this.title[1] = `วันที่ ${data.start} ถึง ${data.end}`;
          // this.searchData.id = ''
        })
        .catch(err => {
          this.$Progress.fail();
          console.log("Error @report by machine");
          console.log(err);
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText)
          });
        });
    }
  },
  mounted() {
    this.getClearMoneyReport();
    this.getMachine();
  }
};
</script>
<style>
.spanCustom {
  cursor: pointer;
  color: #20a8d8;
  border-color: #20a8d8;
  text-decoration: underline;
}
.spanCustom:hover {
  text-decoration: none;
  text-shadow: 1px 1px 1px #555;
}
</style>